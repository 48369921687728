<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-database-cog-outline"
          inline
          class="px-5 py-3"
          style="min-height: 300px"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Entidades BDGD Obrigatórias :: {{ company.name }}
            </div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
              >
                As datas abaixo serão utilizadas na exportação da entidade BASE
                da BDGD, para preencher os campos DAT_INC e DAT_FNL.
              </v-alert>
              <v-container
                fluid
                class="d-flex dates"
              >
                <input-date
                  label="Data inicial do snapshot"
                  :rules="{ required: true, min: 10 }"
                  :selectedDate="dateInitial"
                  @dataSelected="(data) => (dateInitial = data)"
                />
                <input-date
                  label="Data final do snapshot"
                  :rules="{ min: 10 }"
                  :selectedDate="dateFinal"
                  @dataSelected="(data) => (dateFinal = data)"
                />
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
              >
                Aqui você pode informar ao sistema quais são as entidades da
                BDGD que a {{ company.name }} precisa enviar para a ANEEL.
              </v-alert>
              <v-container fluid>
                <v-row
                  align="center"
                  justify="start"
                  class="mt-2"
                >
                  <div
                    v-for="(selectedEntity, i) in selectedEntities"
                    :key="selectedEntity"
                    class="d-inline-flex shrink ma-1"
                  >
                    <v-chip
                      :disabled="loading"
                      color="grey lighten-3"
                      close
                      @click:close="selectedEntities.splice(i, 1)"
                    >
                      <div class="d-inline-flex">
                        {{ selectedEntity | toUpperCase }}
                      </div>
                    </v-chip>
                  </div>
                </v-row>
                <v-row class="mt-n5">
                  <v-col
                    md="5"
                    cols="12"
                  >
                    <v-text-field
                      ref="search"
                      class="ml-n2"
                      v-model="search"
                      hide-details
                      prepend-icon="mdi-magnify"
                      label="Pesquisar entidade"
                      single-line
                    />
                  </v-col>
                </v-row>
              </v-container>
              <div
                class="transitioncard"
                :style="{
                  overflow: 'hidden',
                  'max-height': chipContainerHeight
                }"
              >
                <template v-for="entity in filteredEntities">
                  <v-chip
                    outlined
                    class="mr-1 mt-1"
                    v-if="!selectedEntities.includes(entity)"
                    :key="entity"
                    @click="selectedEntities.push(entity)"
                  >
                    {{ entity | toUpperCase }}
                  </v-chip>
                </template>
              </div>
              <div
                justify="center"
                align="center"
                class="mt-n3"
              >
                <v-btn
                  v-if="
                    chipContainerHeight != '100%' && bdgdEntities.length > 0
                  "
                  class="mt-2 mb-n3 ml-n7"
                  icon
                  color="gray"
                  @click="chipContainerHeight = '100%'"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn
                  v-else-if="bdgdEntities.length > 0"
                  class="mt-2 mb-n3 ml-n7"
                  icon
                  color="gray"
                  @click="chipContainerHeight = '37px'"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          min-width="100"
          style="float: right"
          class="mt-n5 mr-0"
          @click="save()"
          :disabled="btnSaveDisabled"
          :loading="loading"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyService from '@/services/CompanyService';
import EntidadesBdgdService from '@/services/EntidadesBdgdService';
import ExportacoesBdgdsDistribuidorasService from '@/services/ExportacoesBdgdsDistribuidorasService';
import routes from '@/store/modules/routes';

export default {
  name: 'EmpresaEntidadesObrigatorias',

  components: {
    InputDate: () => import('@/components/general/InputDate.vue')
  },

  data: () => ({
    company: { name: '' },
    bdgdEntities: [],
    selectedEntities: [],
    loading: false,
    chipContainerHeight: '37px',
    search: '',
    dateInitial: null,
    dateFinal: null,
    dateFormattedInitial: '',
    dateFormattedFinal: '',
    menu1: false,
    menu2: false,
    rulesDateInitial: { rules: 'required|min:10' },
    rulesDateFinal: { rules: 'min:10' }
  }),

  mounted() {
    this.getCompany(this.$route.params.id);
    this.getAllBdgdEntities();
    this.getDatesBdgdEntities();
  },

  computed: {
    filteredEntities() {
      const search = this.search.toLowerCase();
      if (!search) return this.bdgdEntities;
      return this.bdgdEntities.filter((entity) =>
        entity.toLowerCase().includes(search)
      );
    },

    btnSaveDisabled() {
      return !this.selectedEntities.length;
    }
  },

  methods: {
    getCompany(id) {
      CompanyService.getCompanyById(id)
        .then((response) => {
          const { name, mandatory_bdgd_entities: selectedEntities } = response;
          this.company.name = name;
          this.selectedEntities = selectedEntities;
        })
        .catch(() =>
          this.$toast.error('Erro ao buscar os dados da empresa.', '', {
            position: 'topRight'
          })
        );
    },

    getAllBdgdEntities() {
      EntidadesBdgdService.getEntidades()
        .then((response) => (this.bdgdEntities = response.data))
        .catch(() =>
          this.$toast.error('Erro ao buscar as entidades da BDGD.', '', {
            position: 'topRight'
          })
        );
    },

    save() {
      this.loading = true;

      const data = {
        mandatory_bdgd_entities: this.selectedEntities,
        date_initial: this.dateInitial,
        date_final: this.dateFinal
      };

      if (!data.date_initial) {
        this.$toast.error('Deve selecionar a data inicial do snapshot.', '', {
          position: 'topRight'
        });
        this.errorDateInitial = 'Deve selecionar a data inicial do snapshot.';
        this.loading = false;
        return;
      }

      CompanyService.updateMandatoryBdgdEntities(this.$route.params.id, data)
        .then(() => {
          this.$toast.success('Entidades configuradas com sucesso.', '', {
            position: 'topRight'
          });
          this.updateMandatoryBdgdEntitiesLoggedCompany(this.selectedEntities);
          this.$router.replace({
            name: routes.state.appRoutes['EMPRESAS_LISTAR']
          });
        })
        .catch(() =>
          this.$toast.error(
            'Erro ao salvar as entidades da BDGD obrigatórias para a empresa.',
            '',
            { position: 'topRight' }
          )
        )
        .finally(() => (this.loading = false));
    },

    updateMandatoryBdgdEntitiesLoggedCompany(selectedEntities) {
      const targetCompanyId = this.$route.params.id;
      const loggedCompanyId = this.$store.getters.getCompanyId;

      if (targetCompanyId == loggedCompanyId)
        return this.$store.dispatch(
          'setCompanyMandatoryBdgdEntities',
          selectedEntities
        );
    },

    getDatesBdgdEntities() {
      ExportacoesBdgdsDistribuidorasService.getExportacao(this.$route.params.id)
        .then((response) => {
          if (response.data) {
            this.dateInitial = response.data.data_inicio;
            this.dateFinal = response.data.data_fim;
          }
        })
        .catch((error) => 'Não foi possivel recuperar as datas: ' + error);
    }
  }
};
</script>

<style>
.dates > span:last-child {
  padding-left: 50px !important;
}
</style>
